


































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class EditVariableDialog extends Vue {
    dialog = false;

    confirm(): void {
        this.$emit('onConfirm');
        this.dialog = false;
    }
}
